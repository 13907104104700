import React, {useEffect, useState} from "react";
import {formOptions, createAuthHeader, fetchUrl, useFilter, formatDate} from "../../../utils/util";
import {FaBuilding, FaCalendar, FaClock, FaInfoCircle, FaMapMarkerAlt, FaUser, FaVideo} from "react-icons/fa";
import {FaR} from "react-icons/fa6";
import EditJobModal from "./EditJobModal";


export default function AuftraegeanzeigenClient() {
    const [jobs, setJobs] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [editModalOpen, setEditModalOpen] = useState(false);
    const [currentJob, setCurrentJob] = useState(null);

    const filterFunctions = {
        language: (job, value) => job.language.toLowerCase().includes(value.toLowerCase()),
        date: (job, value) => new Date(job.date).toDateString() === new Date(value).toDateString(),
    };
    const {filters, handleFilterChange, filteredItems: filteredJobs} = useFilter(jobs, filterFunctions);

    const renderFilterOptions = () => {
        return (
            <div className="flex flex-col md:flex-row items-center justify-center gap-2 md:gap-4 mb-4">
                <div className="flex items-center gap-2">
                    <label htmlFor="languageFilter">Sprache:</label>
                    <select
                        id="languageFilter"
                        name="language"
                        value={filters.language || ''}
                        onChange={handleFilterChange}
                        className="border p-2 rounded"
                    >
                        <option value="">Alle</option>
                        {formOptions.languages.map((option) => (
                            <option key={option.value} value={option.value}>{option.label}</option>
                        ))}
                    </select>
                </div>
                <div className="flex items-center gap-2">
                    <label htmlFor="dateFilter">Datum:</label>
                    <input
                        type="date"
                        id="dateFilter"
                        name="date"
                        value={filters.date || ''}
                        onChange={handleFilterChange}
                        className="border p-2 rounded"
                    />
                </div>
            </div>
        );
    };


    // Anwenden der Filterfunktion auf die Aufträge


    useEffect(() => {
        async function fetchJobs() {
            const userId = localStorage.getItem('userId');
            if (!userId) {
                console.error('Benutzer-ID nicht gefunden.');
                setError('Benutzer-ID nicht gefunden.');
                setLoading(false);
                return;
            }
            try {
                const response = await fetch(`${fetchUrl}/api/jobs/user/${userId}`, {
                    headers: {
                        ...createAuthHeader(),
                    }
                });

                if (response.ok) {
                    const data = await response.json();
                    setJobs(data);
                } else {
                    throw new Error('Fehler beim Laden der Jobs: ' + response.status);
                }
            } catch (error) {
                console.error(error);
                setError(error.message);
            } finally {
                setLoading(false);
            }
        }

        fetchJobs();
    }, []);

    const openEditModal = (jobToEdit) => {
        const date = new Date(jobToEdit.date);
        const formattedDate = date.toISOString().slice(0, 16);
        setCurrentJob({...jobToEdit, date: formattedDate});
        setEditModalOpen(true);
    };

    const closeEditModal = () => {
        setEditModalOpen(false);
        setCurrentJob(null);
    };

    const handleSave = (updatedJob) => {
        setJobs(jobs => jobs.map(job => job.id === updatedJob.id ? updatedJob : job));
        closeEditModal();
    };

    if (loading) {
        return <div>Laden...</div>;
    }

    if (error) {
        return <div>Fehler: {error}</div>;
    }


    const isDeleteDisabled = (jobDate) => {
        const currentTime = new Date();
        const jobTime = new Date(jobDate);
        const timeDifference = jobTime - currentTime;
        const hoursDifference = timeDifference / (1000 * 60 * 60);
        return hoursDifference < 24;
    };

    function handleDelete(job) {
        if (!isDeleteDisabled(job.date) && window.confirm(`Sind Sie sicher, dass Sie den Auftrag ${job.id} löschen wollen?`)) {
            fetch(fetchUrl + "/api/jobs/client/" + job.id, {
                method: 'DELETE',
                headers: {
                    ...createAuthHeader(),
                },
            })
                .then((response) => {
                    if (!response.ok) {
                        throw new Error('Fehler beim Löschen des Auftrags');
                    }
                    setJobs(jobs.filter((j) => j.id !== job.id));
                })
                .catch((error) => {
                    console.error('Fehler beim Löschen des Auftrags', error);
                });
        }
    }

    return (
        <div className="container mx-auto mt-8 px-4">
            <h1 className="text-2xl font-bold mb-8 text-center">Meine Aufträge</h1>
            {renderFilterOptions()}
            {filteredJobs.length > 0 ? (
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
                    {filteredJobs.map((job) => (
                        <div key={job.id}
                             className={`border rounded-lg shadow-lg bg-white flex flex-col h-full transition-transform transform hover:scale-105 ${
                                 job.jobType === 'vorort' ? 'border-red-500' : 'border-blue-500'
                             }`}
                             id={`job-${job.id}`}
                        >
                            {/* Job-Type Badge */}
                            <div id={`tag-${job.id}-${job.jobType === 'vorort' ? 'vorOrt' : 'video'}`}
                                className={`absolute top-2 right-2 px-2 py-1 rounded-full text-white font-bold ${
                                job.jobType === 'vorort' ? 'bg-red-500' : 'bg-blue-500'
                            }`}>
                                {job.jobType === 'vorort' ? 'Vor-Ort' : 'Video'}
                            </div>

                            <div className="p-6 flex-grow">
                                {/* Sprache und Branche */}
                                <h3 className="text-lg font-bold mb-4">
                                    {job.language} - {job.industry}
                                </h3>

                                {/* Details */}
                                <div className="grid grid-cols-2 gap-4">
                                    {/* First Column */}
                                    <div>
                                        <div className="flex items-center mb-2">
                                            <FaCalendar className="text-blue-500 mr-2" />
                                            <p><strong>Datum:</strong> {formatDate(new Date(job.date))}</p>
                                        </div>
                                        <div className="flex items-center mb-2">
                                            <FaClock className="text-blue-500 mr-2" />
                                            <p><strong>Dauer:</strong> {job.duration} Minuten</p>
                                        </div>
                                        {job.jobType === 'vorort' && (
                                            <>
                                                <div className="flex items-center mb-2">
                                                    <FaMapMarkerAlt className="text-blue-500 mr-2" />
                                                    <p><strong>Stadt:</strong> {job.locationDTO.city}</p>
                                                </div>
                                                <div className="flex items-center mb-2">
                                                    <FaBuilding className="text-blue-500 mr-2" />
                                                    <p><strong>Adresse:</strong> {job.locationDTO.address}</p>
                                                </div>
                                                <div className="flex items-center mb-2">
                                                    <FaR className="text-blue-500 mr-2" />
                                                    <p><strong>Raum:</strong> {job.locationDTO.room}</p>
                                                </div>
                                            </>
                                        )}
                                    </div>

                                    {/* Second Column */}
                                    <div>
                                        <div className="flex items-center mb-2">
                                            <FaUser className="text-blue-500 mr-2" />
                                            <p><strong>Dolmetscher:</strong> {job.interpreterId === null ? 'Nicht zugewiesen' : job.assignedInterpreter}</p>
                                        </div>
                                        {job.jobType === 'video' && (
                                            <div className="flex items-center mb-2">
                                                <FaVideo className="text-blue-500 mr-2" />
                                                <p><strong>Link:</strong> {job.meetinLink ? <a href={'/dashboard-client'+job.meetinLink} target="_blank" rel="noopener noreferrer">Video Call Seite</a> : 'Kein Link verfügbar'}</p>
                                            </div>
                                        )}
                                        {job.additionalInfo && (
                                            <div className="flex items-center mb-2">
                                                <FaInfoCircle className="text-blue-500 mr-2" />
                                                <p><strong>Zusätzliche Infos:</strong> {job.additionalInfo}</p>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>

                            {/* Buttons */}
                            <div className="mt-auto grid grid-cols-3 gap-0.5">
                                <button
                                    onClick={() => openEditModal(job)}
                                    className="col-span-2 bg-homeTextGradient text-white font-bold py-2 px-4 rounded-bl-lg hover:bg-sidebarGradient"
                                    id={`editButton-${job.id}`}
                                >
                                    Bearbeiten
                                </button>
                                <button
                                    className={`col-span-1 ${
                                        isDeleteDisabled(job.date)
                                            ? 'bg-gray-400 cursor-not-allowed'
                                            : 'bg-red-500 hover:bg-red-700'
                                    } text-white font-bold py-2 px-4 rounded-br-lg transition-colors`}
                                    onClick={() => {handleDelete(job)}}
                                    id={`deleteButton-${job.id}`}
                                    disabled={isDeleteDisabled(job.date)}
                                >
                                    Löschen
                                </button>
                            </div>
                        </div>
                    ))}
                </div>
            ) : (
                <div className="text-center text-gray-500">Keine Aufträge vorhanden.</div>
            )}
            {editModalOpen && currentJob && (
                <EditJobModal
                    job={currentJob}
                    onClose={closeEditModal}
                    onSave={handleSave}
                />
            )}
        </div>
    );
}