import React, {useEffect, useState} from 'react';
import {useSearchParams} from "react-router-dom";
import {fetchUrl} from "../../utils/util";
import Navbar from "../../componenten/shared/navbar";

function CertificationPage() {
    const [loaded, setLoaded] = useState(false);
    const [error, setError] = useState(false);
    const [countdown, setCountdown] = useState(5); // Zähler für den Countdown
    const [keyParams] = useSearchParams();
    const token = keyParams.get("key")?.replace("key=", "").replaceAll(" ", "+") || "";

    useEffect(() => {
        if (token) {
            console.log("Send:", token);
            sendToken();
        }
    }, [token]);

    useEffect(() => {
        if (loaded && !error) {
            const interval = setInterval(() => {
                setCountdown((prevCount) => prevCount - 1);
            }, 1000);

            if (countdown === 0) {
                window.location.href = "/login"; // Weiterleitung zur Login-Seite
            }

            return () => clearInterval(interval); // Säubere den Timer, wenn Komponente unmontiert wird
        }
    }, [loaded, error, countdown]);

    // Asynchrone Funktion zum Senden des Tokens an das Backend
    async function sendToken() {
        try {
            const response = await fetch(`${fetchUrl}/api/users/certify`, {
                method: 'POST', headers: {
                    'Content-Type': 'application/json',
                }, body: token,
            });

            if (response.ok) {
                setError(false);
            } else {
                setError(true);
            }
        } catch (e) {
            console.log(e);
            setError(true);
        } finally {
            setLoaded(true);
        }
    }

    // Extract the nested ternary logic into a separate statement
    let content;
    if (error && loaded) {
        content = (<p>Es ist ein Fehler aufgetreten, bitte versuche es später erneut.</p>);
    } else if (loaded && !error) {
        content = (<div>
            <p>Danke für deine Registrierung, dein Account wurde verifiziert.</p>
            <p>Du wirst in <b>{countdown}</b> Sekunden weitergeleitet.</p>
        </div>);
    } else {
        content = (<div>
            <p>Verifiziere deinen Account...</p>
            <div className="flex items-center justify-center w-full" style={{height: "200px"}}>
                <div className="spinner"></div>
            </div>
        </div>);
    }

    return (<div className="">
            <Navbar/>
            <div className="flex items-center justify-center h-screen p-4">
                <div
                    className="flex w-full max-w-5xl bg-white border border-black rounded-lg shadow-lg border-opacity-20">
                    <div className="w-full p-10">
                        <div className="text-center text-duoColours-2 text-2xl">
                            {content}
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
}

export default CertificationPage;
