import React, {useState} from "react";
import {formatDate} from "../../../utils/util";
import PropTypes from "prop-types";


function ConfirmAcceptModal({open, onConfirm, onCancel, job, error}) {
    const [isChecked, setIsChecked] = useState(false);
    const [jobError, setJobError] = useState('');
    const [isLoading, setIsLoading] = useState(false);


    const handleConfirmClick = () => {
        if (isChecked) {
            onConfirm();
            setJobError('');
        } else {
            setJobError('Bitte bestätigen Sie, dass Sie den Auftrag verbindlich annehmen möchten.');
        }
    };

    if (!open) return null;

    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 backdrop-blur-sm flex justify-center items-center z-50">
            <div
                className={`bg-white p-6 rounded-lg shadow-lg w-full max-w-md mx-4 md:mx-0 my-4 overflow-y-auto ${error ? 'bg-gray-100' : ''}`}>
                <h3 className="text-xl font-bold mb-4 text-center">
                    {error ? 'Auftrag vergeben' : 'Auftrag akzeptieren'}
                </h3>
                {error ? (
                    <div className="text-center mb-4">
                        <p>Dieser Auftrag wurde bereits von einem anderen Interpreter angenommen.</p>
                    </div>
                ) : (
                    <>
                        <p className="text-center mb-4">Möchten Sie den Job
                            am <b>{formatDate(new Date(job.date))}</b> Uhr annehmen?</p>
                        <div className="my-4 flex items-center justify-center">
                            <input
                                id="confirmCheck"
                                type="checkbox"
                                checked={isChecked}
                                onChange={(e) => setIsChecked(e.target.checked)}
                                className="form-checkbox h-6 w-6 mr-2"
                            />
                            <label htmlFor="confirmCheck">Ich bestätige verbindlich die Annahme des Auftrags.</label>
                        </div>
                        {jobError && <p className="text-red-500 text-center">{jobError}</p>}
                    </>
                )}

                <div className="flex justify-center space-x-4 mt-4">
                    {error ? (
                        <button
                            onClick={() => {
                                onCancel();
                                window.location.reload(); // Seite aktualisieren
                            }}
                            className="bg-homeTextGradient text-white font-bold py-2 px-4 rounded"
                        >
                            OK
                        </button>
                    ) : (
                        <>
                            <button
                                onClick={onCancel}
                                className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
                            >
                                Abbrechen
                            </button>
                            <button
                                onClick={handleConfirmClick}
                                className="bg-homeTextGradient text-white font-bold py-2 px-4 rounded"
                            >
                                {isLoading ? (
                                    <div className="flex items-center justify-center text-white">
                                        <div className="spinnerButton"></div>
                                    </div>
                                ) : (
                                    'Bestätigen'
                                )}
                            </button>
                        </>
                    )}
                </div>
            </div>
        </div>
    );
}

ConfirmAcceptModal.propTypes = {
    open: PropTypes.bool.isRequired,
    onConfirm: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    job: PropTypes.shape({
        date: PropTypes.string.isRequired,
    }).isRequired,
    error: PropTypes.bool,
};


export default ConfirmAcceptModal;