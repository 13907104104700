import React, {useState} from 'react';
import VorOrtAuftragForm from './VorOrtAuftragForm';
import VideoAuftragForm from './VideoAuftragForm';
import {FaMapMarkerAlt, FaVideo} from 'react-icons/fa';

const CreateJobNew = () => {
    const [selectedOrderType, setSelectedOrderType] = useState(null);

    const handleSelection = (type) => {
        setSelectedOrderType(type);
    };

    return (
        <div className="p-8">
            <h2 className="text-2xl font-bold mb-6 text-center">Wählen Sie den Auftragstyp aus</h2>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                <button
                    id="vorOrtAuftragButton"
                    onClick={() => handleSelection('vorOrt')}
                    className={`cursor-pointer p-4 rounded-lg shadow-md transition-transform transform hover:scale-105
                 ${selectedOrderType === 'vorOrt' ? 'bg-red-200' : 'bg-white'} border border-gray-200 hover:shadow-lg flex flex-col items-center text-center`}
                >
                    <FaMapMarkerAlt className="text-3xl text-blue-500 mb-2"/>
                    <h3 className="text-lg font-semibold">Vor-Ort-Auftrag</h3>
                    <p className="text-gray-600 text-sm">Vor-Ort-Termin erstellen.</p>
                </button>
                <button
                    id="videoAuftragButton"
                    onClick={() => handleSelection('video')}
                    className={`cursor-pointer p-4 rounded-lg shadow-md transition-transform transform hover:scale-105
                 ${selectedOrderType === 'video' ? 'bg-blue-200' : 'bg-white'} border border-gray-200 hover:shadow-lg flex flex-col items-center text-center`}
                >
                    <FaVideo className="text-3xl text-blue-500 mb-2"/>
                    <h3 className="text-lg font-semibold">Video-Auftrag</h3>
                    <p className="text-gray-600 text-sm">Video-Anruf erstellen.</p>
                </button>
            </div>

            <div className="mt-6">
                {selectedOrderType === 'vorOrt' && <VorOrtAuftragForm/>}
                {selectedOrderType === 'video' && <VideoAuftragForm/>}
            </div>
        </div>
    );
};

export default CreateJobNew;
