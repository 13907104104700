import React from "react";
import PropTypes from "prop-types";

const InterpreterSelection = ({ job, setJob, interpreters, selectSpecificInterpreter, setSelectSpecificInterpreter }) => {
    return (
        <div className="p-4 bg-gray-50 rounded-lg shadow-inner space-y-4">
            <p className="block text-sm font-medium text-gray-700 pb-2">Spezifischen Dolmetscher wählen?</p>
            <div className="flex items-center space-x-4">
                <label className="inline-flex items-center">
                    <input
                        type="radio"
                        name="selectInterpreter"
                        value="yes"
                        checked={selectSpecificInterpreter}
                        onChange={() => setSelectSpecificInterpreter(true)}
                        disabled={!job.language}
                        className="form-radio text-blue-600"
                        id={"selectInterpreterFormYes"}
                    />
                    <span className="ml-2">Ja</span>
                </label>
                <label className="inline-flex items-center">
                    <input
                        type="radio"
                        name="selectInterpreter"
                        value="no"
                        checked={!selectSpecificInterpreter}
                        onChange={() => {
                            setSelectSpecificInterpreter(false);
                            setJob({
                                ...job,
                                suggestedInterpreterId: -1,
                            });
                        }}
                        className="form-radio text-blue-600"
                        id={"selectInterpreterFormNo"}
                    />
                    <span className="ml-2">Nein</span>
                </label>
            </div>

            {selectSpecificInterpreter && (
                <select
                    className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                    value={job.suggestedInterpreterId || ''}
                    onChange={(e) => {
                        const selectedInterpreter = interpreters.find(interpreter => interpreter.id === Number(e.target.value));
                        setJob({
                            ...job,
                            suggestedInterpreterId: e.target.value || null,
                            interpreter: selectedInterpreter || null
                        });
                    }}
                    id={"chooseInterpreterForm"}
                >
                    <option value="">Dolmetscher auswählen</option>
                    {interpreters.map((interpreter) => (
                        <option key={interpreter.id} value={interpreter.id}>
                            {interpreter.firstName} {interpreter.lastName}
                        </option>
                    ))}
                </select>
            )}
        </div>
    )
}

InterpreterSelection.propTypes = {
    job: PropTypes.shape({
        language: PropTypes.string,
        suggestedInterpreterId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        interpreter: PropTypes.shape({
            id: PropTypes.number,
            firstName: PropTypes.string,
            lastName: PropTypes.string
        })
    }).isRequired,
    setJob: PropTypes.func.isRequired,
    interpreters: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.number.isRequired,
            firstName: PropTypes.string.isRequired,
            lastName: PropTypes.string.isRequired
        })
    ).isRequired,
    selectSpecificInterpreter: PropTypes.bool.isRequired,
    setSelectSpecificInterpreter: PropTypes.func.isRequired
};

export default InterpreterSelection;