import React from "react";
import { Analytics } from "@vercel/analytics/react";
import { SpeedInsights } from "@vercel/speed-insights/react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Login from "./pages/Login";
import Registration from "./pages/Registration";
import LayoutDashboard from "./componenten/shared/LayoutDashboard";
import DashboardClient from "./pages/client/DashboardClient";
import DashboardInterpreter from "./pages/interpreter/DashboardInterpreter";
import Homepage from "./pages/Homepage";
import { AuthProvider } from "./utils/AuthProvider";
import PrivateRoute from "./utils/PrivateRoute";
import AuftraegeanzeigenClient from "./pages/client/aufträgeAnzeigen/AuftraegeanzeigenClient";
import AuftraegeanzeigenInterpreter from "./pages/interpreter/aufträgeAnzeigen/AuftraegeanzeigenInterpreter";
import MeineAuftraegeInterpreter from "./pages/interpreter/MeineAuftraegeInterpreter";
import ProfileInterpreter from "./pages/interpreter/ProfileInterpreter";
import ProfileClient from "./pages/client/ProfileClient";
import CertificationPage from "./pages/standardPage/CertificationPage";

import Feedback from "./pages/Feedback";
import BaseLayout from "./componenten/shared/BaseLayout";
import { DashboardAdmin } from "./pages/admin/DashboardAdmin";
import { NutzerAdmin } from "./pages/admin/NutzerAdmin";
import { AuftraegeAdmin } from "./pages/admin/AuftraegeAdmin";
import { NutzerIDAdmin } from "./pages/admin/NutzerIDAdmin";
import { JobIDAdmin } from "./pages/admin/JobIDAdmin";
import { Feedbacks } from "./pages/admin/Feedbacks";
import Impressum from "./pages/standardPage/Impressum";
import Datenschutz from "./pages/standardPage/Datenschutz";
import Clients from "./pages/clients";
import Interpreters from "./pages/interpreters";
import Einladen from "./pages/Einladen";
import CreateJobNew from "./pages/client/auftragErstellen/CreateJobNew";
import MeetingPage from "./pages/videoFeature/MeetingPage";
import ResetPassword from "./pages/ResetPassword";

function App() {
    return (
        <AuthProvider>
            {" "}
            {/* Umwickeln Sie Ihre Routes mit AuthProvider */}
            <Router>
                <Analytics />
                <SpeedInsights />
                <Routes>
                    <Route path="/" element={<BaseLayout />}>
                        <Route index element={<Homepage />} />
                        <Route path="login" element={<Login />} />
                        <Route path="register" element={<Registration />} />
                        <Route path="impressum" element={<Impressum />} />
                        <Route path="datenschutz" element={<Datenschutz />} />
                        <Route path="certify" element={<CertificationPage />} />
                        <Route path="clients" element={<Clients />} />
                        <Route path="interpreters" element={<Interpreters />} />
                        <Route path="reset-password" element={<ResetPassword />} />
                    </Route>

                    {/*<Route path="/test" element={<TestPage/>}/>*/}

                    <Route element={<PrivateRoute allowedRoles={['CLIENT']}/>}>
                        <Route path="/dashboard-client" element={<LayoutDashboard/>}>
                            <Route index element={<DashboardClient/>}/>
                            <Route path="auftraege-erstellen" element={<CreateJobNew/>}/>
                            <Route path="auftraege-anzeigen" element={<AuftraegeanzeigenClient/>}/>
                            <Route path="profile" element={<ProfileClient/>}/>
                            <Route path="feedback" element={<Feedback/>}/>
                            <Route path="meeting/:jobId/:uuid" element={<MeetingPage />} />
                            <Route path="einladen" element={<Einladen/>}/>
                        </Route>
                    </Route>

                    <Route element={<PrivateRoute allowedRoles={['INTERPRETER']}/>}>
                        <Route path="/dashboard-interpreter" element={<LayoutDashboard/>}>
                            <Route index element={<DashboardInterpreter/>}/>
                            <Route path="meine-auftraege" element={<MeineAuftraegeInterpreter/>}/>
                            <Route path="auftraege-anzeigen" element={<AuftraegeanzeigenInterpreter/>}/>
                            <Route path="profile" element={<ProfileInterpreter/>}/>
                            <Route path="feedback" element={<Feedback/>}/>
                            <Route path="meeting/:jobId/:uuid" element={<MeetingPage />} />
                            <Route path="einladen" element={<Einladen/>}/>
                        </Route>
                    </Route>
                    <Route element={<PrivateRoute allowedRoles={["ADMIN"]} />}>
                        <Route path="/dashboard-admin" element={<LayoutDashboard />}>
                            <Route index element={<DashboardAdmin />} />
                            <Route path="nutzer" element={<NutzerAdmin />} />
                            <Route path="auftraege" element={<AuftraegeAdmin />} />
                            <Route path="nutzer/:userID" element={<NutzerIDAdmin />} />
                            <Route path="auftraege/:jobID" element={<JobIDAdmin />} />
                            <Route path="feedbacks" element={<Feedbacks />} />
                        </Route>
                    </Route>
                </Routes>
            </Router>
        </AuthProvider>
    );
}


export default App;
