import React, {useEffect, useState} from "react";
import {createAuthHeader, fetchUrl} from "../../utils/util";
import {FaBuilding, FaCalendar, FaClock, FaInfoCircle, FaMapMarkerAlt,  FaVideo} from "react-icons/fa";
import {FaR} from "react-icons/fa6";

export default function MeineAuftraegeInterpreter() {
    const [jobs, setJobs] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        async function fetchJobs() {
            const interpreterId = localStorage.getItem('userId');
            if (!interpreterId) {
                console.error('Benutzer-ID nicht gefunden.');
                setError('Benutzer-ID nicht gefunden.');
                setLoading(false);
                return;
            }
            try {

                const response = await fetch(`${fetchUrl}/api/jobs/interpreter/jobs?userId=${interpreterId}&type=accepted`, {
                    headers: {
                        'Content-Type': 'application/json',
                        ...createAuthHeader(),
                    },
                });

                if (!response.ok) {
                    setError('Fehler beim Laden der verfügbaren Jobs: ' + response.status);
                    return;
                }
                const data = await response.json();
                setJobs(data);
            } catch (error) {
                console.error(error);
                setError(error.message);
            } finally {
                setLoading(false);
            }
        }

        fetchJobs();
    }, []);

    if (loading) {
        return <div className="text-center mt-10">Laden...</div>;
    }

    if (error) {
        return <div className="text-center mt-10 text-red-500">Fehler: {error}</div>;
    }

    return (
        <div className="container mx-auto mt-10 px-4">
            <h1 className="text-2xl font-bold mb-8 text-center">Meine Aufträge</h1>
            {jobs.length > 0 ? (
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
                    {jobs.map((job) => (
                        <div key={job.id}
                             className={`border rounded-lg shadow-lg bg-white flex flex-col h-full transition-transform transform hover:scale-105 ${
                                 job.jobType === 'vorort' ? 'border-red-500' : 'border-blue-500'
                             }`}
                             id={`job-${job.id}`}
                        >
                            {/* Job-Type Badge */}
                            <div className={`absolute top-2 right-2 px-2 py-1 rounded-full text-white font-bold ${
                                job.jobType === 'vorort' ? 'bg-red-500' : 'bg-blue-500'
                            }`}>
                                {job.jobType === 'vorort' ? 'Vor-Ort' : 'Video'}
                            </div>

                            <div className="p-6 flex-grow">
                                <h3 className="text-lg font-bold mb-4">
                                    {job.language} - {job.industry}
                                </h3>

                                {/* Conditionally Render Job Details */}
                                <div className="mb-2">
                                    {job.jobType === 'vorort' ? (
                                        <>
                                            {/* Vor-Ort job details */}
                                            <div className="flex items-center mb-2">
                                                <FaMapMarkerAlt className="text-blue-500 mr-2"/>
                                                <p><strong>Stadt:</strong> {job.locationDTO?.city || 'Nicht verfügbar'}</p>
                                            </div>
                                            <div className="flex items-center mb-2">
                                                <FaBuilding className="text-blue-500 mr-2"/>
                                                <p><strong>Adresse:</strong> {job.locationDTO?.address || 'Nicht verfügbar'}</p>
                                            </div>
                                            <div className="flex items-center mb-2">
                                                <FaR className="text-blue-500 mr-2"/>
                                                <p><strong>Raum:</strong> {job.locationDTO?.room || 'Nicht verfügbar'}</p>
                                            </div>
                                        </>
                                    ) : (
                                        <>
                                            {/* Video job details */}
                                            <div className="flex items-center mb-2">
                                                <FaVideo className="text-blue-500 mr-2"/>
                                                <p><strong>Link:</strong> {job.meetinLink ? <a href={'/dashboard-interpreter'+job.meetinLink} target="_blank" rel="noopener noreferrer">Video Call Seite</a> : 'Kein Link verfügbar'}</p>
                                            </div>
                                        </>
                                    )}

                                    {/* Common Info for both job types */}
                                    <div className="flex items-center mb-2">
                                        <FaCalendar className="text-blue-500 mr-2"/>
                                        <p><strong>Datum:</strong> {new Date(job.date).toLocaleDateString('de-DE')} {new Date(job.date).toLocaleTimeString('de-DE')}</p>
                                    </div>
                                    <div className="flex items-center mb-2">
                                        <FaClock className="text-blue-500 mr-2"/>
                                        <p><strong>Dauer:</strong> {job.duration || 'Nicht verfügbar'} Minuten</p>
                                    </div>

                                    {/* Additional Info */}
                                    {job.additionalInfo && (
                                        <div className="flex items-center mb-2">
                                            <FaInfoCircle className="text-blue-500 mr-2"/>
                                            <p><strong>Zusätzliche Infos:</strong> {job.additionalInfo}</p>
                                        </div>
                                    )}
                                </div>
                            </div>

                        </div>
                    ))}
                </div>
            ) : (
                <div className="text-center mt-10 text-gray-500">
                    Sie haben keine akzeptierten Aufträge in der Zukunft.
                </div>
            )}
        </div>
    );
}
