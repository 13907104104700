import React, {useEffect, useState} from "react";
import {createAuthHeader, fetchUrl, formOptions} from "../../../utils/util";
import ReactDatePicker from "react-datepicker";
import {de} from "date-fns/locale";
import PropTypes from "prop-types";

function EditJobModal({job, onClose, onSave}) {
    const [editedJob, setEditedJob] = useState({...job});
    const [isChanged, setIsChanged] = useState(false);
    const [changedFields, setChangedFields] = useState({});

    useEffect(() => {
        const hasChanges = Object.keys(job).some(key => {
            if (job[key] instanceof Object && editedJob[key] instanceof Object) {
                return JSON.stringify(job[key]) !== JSON.stringify(editedJob[key]);
            }
            return job[key] !== editedJob[key];
        });
        setIsChanged(hasChanges);
    }, [editedJob, job]);


    const handleSave = async (e) => {
        e.preventDefault();
        try {
            const response = await fetch(`${fetchUrl}/api/jobs/client/${editedJob.id}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    ...createAuthHeader(),
                },
                body: JSON.stringify(editedJob),
            });
            if (!response.ok) {
                throw new Error('Fehler beim Aktualisieren des Jobs');
            }
            const updatedJob = await response.json();
            onSave(updatedJob);
            setTimeout(() => {
                window.location.reload();
            }, 500);
        } catch (error) {
            console.error('Fehler beim Speichern des Jobs', error);
        }
    };

    const handleChange = (name, value) => {
        setChangedFields(prev => {
            const newFields = {...prev, [name]: value !== job[name]};
            if (name.startsWith('locationDTO.')) {
                const locationKey = name.split('.')[1];
                const newLocation = {
                    ...editedJob.locationDTO,
                    [locationKey]: value
                };
                return {
                    ...newFields,
                    [`locationDTO.${locationKey}`]: JSON.stringify(newLocation) !== JSON.stringify(job.locationDTO)
                };
            }
            return newFields;
        });

        if (name.startsWith('locationDTO.')) {
            const locationKey = name.split('.')[1];
            setEditedJob(prev => ({
                ...prev,
                locationDTO: {...prev.locationDTO, [locationKey]: value}
            }));
        } else {
            setEditedJob(prev => ({...prev, [name]: value}));
        }
    };

    const getFieldClass = (fieldName) => {
        return changedFields[fieldName] ? 'bg-green-100' : '';
    };

    return (
        <div
            className="fixed inset-0 bg-black bg-opacity-50 backdrop-blur-sm flex justify-center items-center z-50 overflow-y-auto">
            <div
                className="bg-white p-6 rounded-lg shadow-lg w-full max-w-lg mx-4 md:mx-0 my-4 overflow-y-auto"
                style={{maxHeight: '90vh'}}
                id="editJobModal"
            >

                <h2 className="text-2xl font-bold mb-6 text-center">Job bearbeiten</h2>
                <form
                    onSubmit={(e) => {
                        e.preventDefault();
                        if (isChanged) {
                            handleSave(e);
                        }
                    }} className="space-y-3">
                    <div>
                        <href className="block text-sm font-medium text-gray-700">Sprache</href>
                        <select
                            className={`w-full p-2 border rounded mt-1 ${getFieldClass('language')}`}
                            value={editedJob.language}
                            onChange={(e) => handleChange('language', e.target.value)}
                            id="languageInput"
                        >
                            <option value="">Sprache auswählen</option>
                            {formOptions.languages.map((option) => (
                                <option key={option.value} value={option.value}>
                                    {option.label}
                                </option>
                            ))}
                        </select>
                    </div>
                    {editedJob.jobType === 'vorort' && (
                        <>
                            <div>
                                <href className="block text-sm font-medium text-gray-700">Stadt</href>
                                <input
                                    type="text"
                                    className={`w-full p-2 border rounded mt-1 ${getFieldClass('locationDTO.city')}`}
                                    value={editedJob.locationDTO.city}
                                    onChange={(e) => handleChange('locationDTO.city', e.target.value)}
                                    placeholder="Stadt"
                                    id="cityInput"
                                />
                            </div>
                            <div>
                                <href className="block text-sm font-medium text-gray-700">Adresse</href>
                                <input
                                    type="text"
                                    className={`w-full p-2 border rounded mt-1 ${getFieldClass('locationDTO.address')}`}
                                    value={editedJob.locationDTO.address}
                                    onChange={(e) => handleChange('locationDTO.address', e.target.value)}
                                    placeholder="Adresse"
                                    id="addressInput"
                                />
                            </div>
                            <div>
                                <href className="block text-sm font-medium text-gray-700">Raum</href>
                                <input
                                    type="text"
                                    className={`w-full p-2 border rounded mt-1 ${getFieldClass('locationDTO.room')}`}
                                    value={editedJob.locationDTO.room}
                                    onChange={(e) => handleChange('locationDTO.room', e.target.value)}
                                    placeholder="Raum"
                                    id="roomInput"
                                />
                            </div>
                        </>
                    )}
                    <div>
                        <href className="block text-sm font-medium text-gray-700">Datum</href>
                        <ReactDatePicker
                            selected={job.date ? new Date(job.date) : null}
                            onChange={(date) => handleChange('date', date)}
                            locale={de}
                            placeholderText=" Datum und Uhrzeit auswählen"
                            showTimeSelect
                            timeCaption="Uhrzeit"
                            timeIntervals={15}
                            dateFormat="Pp"
                            minDate={new Date()}
                            className={`w-full p-2 border rounded mt-1 ${getFieldClass('date')}`}
                            wrapperClassName="w-full"
                            calendarClassName="react-datepicker-custom"
                            id="datePicker"
                        />
                    </div>
                    <div>
                        <href className="block text-sm font-medium text-gray-700">Dauer</href>
                        <select
                            className={`w-full p-2 border rounded mt-1 ${getFieldClass('duration')}`}
                            value={editedJob.duration}
                            onChange={(e) => handleChange('duration', e.target.value)}
                            id="durationInput"
                        >
                            <option value="">Dauer auswählen</option>
                            {formOptions.durations.map((option) => (
                                <option key={option.value} value={option.value}>
                                    {option.label}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div>
                        <href className="block text-sm font-medium text-gray-700">Einsatzbranche</href>
                        <select
                            className={`w-full p-2 border rounded mt-1 ${getFieldClass('industry')}`}
                            value={editedJob.industry}
                            onChange={(e) => handleChange('industry', e.target.value)}
                            id="industryInput"
                        >
                            <option value="">Einsatzbranche auswählen</option>
                            {formOptions.industries.map((option) => (
                                <option key={option.value} value={option.value}>
                                    {option.label}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div>
                        <href className="block text-sm font-medium text-gray-700">Zusätzliche Informationen</href>
                        <textarea
                            className={`w-full p-2 border rounded mt-1 ${getFieldClass('additionalInfo')}`}
                            value={editedJob.additionalInfo}
                            onChange={(e) => handleChange('additionalInfo', e.target.value)}
                            placeholder="Zusätzliche Informationen"
                            id="additionalInfoInput"
                        />
                    </div>

                    {/* Schaltflächen zum Speichern und Schließen */}
                    <div className="flex justify-center space-x-4 mt-6">
                        <button
                            type="button"
                            className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
                            onClick={onClose}
                            id="closeButton"
                        >
                            Schließen
                        </button>
                        <button
                            type="submit"
                            disabled={!isChanged}
                            className={`bg-homeTextGradient text-white font-bold py-2 px-4 rounded ${!isChanged ? 'opacity-50 cursor-not-allowed' : ''}`}
                            id="saveButton"
                        >
                            Speichern
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );

}

// Adding propTypes for validation
EditJobModal.propTypes = {
    job: PropTypes.shape({
        id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
        language: PropTypes.string,
        jobType: PropTypes.string,
        locationDTO: PropTypes.shape({
            city: PropTypes.string,
            address: PropTypes.string,
            room: PropTypes.string,
        }),
        date: PropTypes.oneOfType([
            PropTypes.instanceOf(Date),
            PropTypes.string,
        ]),
        duration: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        industry: PropTypes.string,
        additionalInfo: PropTypes.string,
        // You can add other properties here if needed
    }).isRequired,
    onClose: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired,
};


export default EditJobModal;